import { AdminEvents } from '../admin/admin';
import { AuthenticationEvents } from '../authentication/authentication';
import { CardEvents } from '../card/card';
import { CollectiveAgreementEvents } from '../collectiveAgreement/collectiveAgreement';
import { AllCompanyEvents } from '../company/company';
import { DashboardEvents } from '../dashboard/dashboard';
import { EmployeeEvents } from '../employee/employee';
import { ReportEvents } from '../events';
import { FinancialEvents } from '../financial/financial';
import { HeaderEvents } from '../header/header';
import { HelpEvents } from '../help/help';
import { IfoodCardEvents } from '../ifood-card/ifood-card';
import { InsightsEvents } from '../insights/insights';
import { LicenseEvents } from '../license/license';
import { MarketplaceEvents } from '../marketplace/marketplace';
import { MenuEvents } from '../menu/menu';
import { NewsEvents } from '../news/news';
import { NotificationEvents } from '../notification/notification';
import { OnboardingEvents } from '../onboarding/onboarding';
import { RechargeEvents } from '../recharge/recharge';
import { EventParamsType, EventsDictionary } from '../types';

export const AllEvents = {
  ...OnboardingEvents,
  ...HeaderEvents,
  ...MenuEvents,
  ...AuthenticationEvents,
  ...CardEvents,
  ...DashboardEvents,
  ...EmployeeEvents,
  ...HelpEvents,
  ...IfoodCardEvents,
  ...NewsEvents,
  ...ReportEvents,
  ...RechargeEvents,
  ...AllCompanyEvents,
  ...FinancialEvents,
  ...AdminEvents,
  ...NotificationEvents,
  ...MarketplaceEvents,
  ...LicenseEvents,
  ...CollectiveAgreementEvents,
  ...InsightsEvents,
};

type EventsTypes = typeof AllEvents;

export type EventType = keyof EventsTypes;

type EventTypeCollection = EventType[];

const EventKeys = Object.keys(AllEvents) as EventTypeCollection;

export const allEvents: EventsDictionary = EventKeys.reduce((collection, eventKey) => {
  const eventName = AllEvents[eventKey] as string;

  return {
    ...collection,
    [eventName]: <EventParams>(params: EventParamsType<EventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 1,
      metadata: params,
    }),
  };
}, {});
