import { EventParamsType, EventsDictionary } from '../types';

export enum InsightsEvents {
  InsightsMenu_view = 'insights:menu_view',
  InsightsHomePage_view = 'insights:homePage_view',
  InsightsHero_view = 'insights:hero_view',
  InsightsCarouseltips_view = 'insights:carouseltips_view',
  InsightsCompany_view = 'insights:company_view',
  InsightsTopUses_view = 'insights:topuses_view',
  InsightsCarouselNews_view = 'insights:carouselnews_view',
  InsightsDurationFBenefits_view = 'insights:durationfbenefits_view',
  InsightsONandoFFComparison_view = 'insights:onandoffcomparasion_view',
  InsightsOnandoFFuses_view = 'insights:onandoffuses_view',
  InsightsTimeperiods_view = 'insights:timeperiods_view',
  InsightsOffuses_view = 'insights:offuses_view',
  InsightsAgerange_view = 'insights:agerange_view',
  InsightsEmployees_view = 'insights:employees_view',
  InsightsMarketComparasion_view = 'insights:marketcomparasion_view',
  InsightsSaving_view = 'insights:saving_view',
  InsightsNps_view = 'insights:nps_view',
  InsightsInfo_view = 'insights:Info_view',
  InsightsHomePageDrawer_view = 'insights:home_page_drawer_view',
  InsightsMenu_click = 'insights:menu_click',
  InsightsCarouseltips_navigation_click = 'insights:carouseltips_navigation_click',
  InsightsCarouseltips_click = 'insights:carouseltips_click',
  InsightsCompanySelect_click = 'insights:company_select_click',
  InsightsCarouselNewsNavigation_click = 'insights:carouselnews_navigation_click',
  InsightsCarouselNews_click = 'insights:carouselnews_click',
  InsightsOffuses_click = 'insights:offuses_click',
  InsightsMarketComparasionMonthyClick = 'insights:marketcomparasion_monthy_click',
  InsightsMarketComparasionDailyClick = 'insights:marketcomparasion_daily_click',
  InsightsMarketComparisonSectorSelect_click = 'insights:marketcomparison_sectorselect_click',
  InsightsHomePageErrorClick = 'insights:homepage_error_click',
  InsightsRechargeClick = 'insights:recharge_click',
  InsightsMoreClick = 'insights:more_click',
  InsightsHomePageErrorCallback = 'insights:homepage_error_callback',
}

export type InsightsEventsType = typeof InsightsEvents;

export type InsightsEventType = keyof InsightsEventsType;

export type InsightsEventTypeCollection = InsightsEventType[];

const CardEventKeys = Object.keys(InsightsEvents) as InsightsEventTypeCollection;

export const Insights: EventsDictionary = CardEventKeys.reduce((collection, eventKey) => {
  const eventName = InsightsEvents[eventKey];

  return {
    ...collection,
    [eventName]: <InsightsEventParams>(params: EventParamsType<InsightsEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
