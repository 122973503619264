import { UserContext } from '@benefits/common-services';
import { ENABLE_HR_AREA, ENABLE_HR_AREA_FOR_EVERYONE, ToggleContext, useFeatureToggle } from '@benefits/features';
import { useContext } from 'react';

export const useHrAreaFeatureEnabledByGroupId = () => {
  const context = useContext(UserContext);
  const groupId = context?.state?.user?.group?.id;
  const featureTogglesContent = useContext(ToggleContext);
  const enabled = featureTogglesContent?.belongsTo(ENABLE_HR_AREA, groupId ?? '');
  const requestSuccess = featureTogglesContent?.isSuccess;
  return requestSuccess && enabled;
};

export const useHrAreaFeatureEnabledForEveryone = () => {
  const [isHrAreaEnabledForEveryone] = useFeatureToggle('ENABLE_HR_AREA_FOR_EVERYONE');

  return [isHrAreaEnabledForEveryone];
};
