import { EventParamsType, EventsDictionary } from '../types';

export enum CompanyEventsEnum {
  COMPANY_BENEFITS_PERMISSION_VIEW = 'Companies:Benefits_permission_View',
  COMPANY_BENEFITS_PERMISSION_MODAL_VIEW = 'Companies:Benefits_permission_modal_View',
  COMPANY_BENEFITS_PERMISSION_MODAL_CLICK = 'Companies:Benefits_permission_modal_click',
  COMPANY_BENEFITS_PERMISSION_CLICK = 'Companies:Benefits_permission_click',
  CompaniesView = 'Companies:CompaniesView',
  CompaniesActionClick = 'Companies:CompaniesActionClick',
  CompaniesRegistrationDataView = 'Companies:CompaniesRegistrationDataView',
  CompaniesRegistrationDataSaveClick = 'Companies:CompaniesRegistrationDataSaveClick',
  CompaniesRegistrationDataBackClick = 'Companies:CompaniesRegistrationDataBackClick',
  CompaniesRegistrationDataConfirmationClick = 'Companies:CompaniesRegistrationDataConfirmationClick',
  CompaniesRegistrationDataCancelSaveClick = 'Companies:CompaniesRegistrationDataCancelSaveClick',
  CompaniesRegistrationDataCancelClick = 'CompaniesRegistrationDataCancelClick',
  CompaniesTermsClick = 'Companies:CompaniesTermsClick',
  // Register Company
  CompaniesRegisterCompanyClick = 'Companies:RegisterCompanyClick',
  CompaniesRegisterCompanyView = 'Companies:RegisterCompanyView',
  CompaniesRegisterCompanyDialogView = 'Companies:RegisterCompanyDialogView',
  CompaniesRegisterCompanyDialogClose = 'Companies:RegisterCompanyDialogClose',
  CompaniesRegisterCompanyDialogBack = 'Companies:RegisterCompanyDialogBack',
  CompaniesRegisterCompanyDialogNext = 'Companies:RegisterCompanyDialogNext',
  CompaniesRegisterCompanyDrawerReviewView = 'Companies:RegisterCompanyDrawerReviewView',
  CompaniesRegisterCompanyDrawerTermsView = 'Companies:RegisterCompanyDrawerTermsView',
  CompaniesRegisterCompanyDrawerReviewClose = 'Companies:RegisterCompanyDrawerReviewClose',
  CompaniesRegisterCompanyDrawerReviewEdit = 'Companies:RegisterCompanyDrawerReviewEdit',
  CompaniesRegisterCompanyDrawerReviewCancel = 'Companies:RegisterCompanyDrawerReviewCancel',
  CompaniesRegisterCompanyDrawerReviewNext = 'Companies:RegisterCompanyDrawerReviewNext',
  CompaniesRegisterCompanyDrawerTermsCloseClick = 'Companies:RegisterCompanyDrawerTermsCloseClick',
  CompaniesRegisterCompanyDrawerTermsCheckClick = 'Companies:RegisterCompanyDrawerTermsCheckClick',
  CompaniesRegisterCompanyDrawerTermDownloadClick = 'Companies:RegisterCompanyDrawerTermDownloadClick',
  CompaniesRegisterCompanyDrawerTermCancelClick = 'Companies:RegisterCompanyDrawerTermCancelClick',
  CompaniesRegisterCompanyDrawerTermNextClick = 'Companies:RegisterCompanyDrawerTermNextClick',
  CompaniesRegisterCompanyFeedbackView = 'Companies:RegisterCompanyFeedbackView',
  CompaniesRegisterCompanyFeedbackHomeClick = 'Companies:RegisterCompanyFeedbackHomeClick',
  CompaniesRegisterCompanyFeedbackCompaniesClick = 'Companies:RegisterCompanyFeedbackCompaniesClick',
  CompaniesRegisterCompanyFeedbackReviewClick = 'Companies:RegisterCompanyFeedbackReviewClick',
  CompaniesRegisterCompanyBackClick = 'Companies:RegisterCompanyBackClick',
  CompaniesRegisterCompanyAddressClick = 'Companies:RegisterCompanyAddressClick',
  CompaniesRegisterCompanyCancelClick = 'Companies:RegisterCompanyCancelClick',
  CompaniesRegisterCompanyNextClick = 'Companies:RegisterCompanyNextClick',
}

export type CompanyEventsType = typeof CompanyEventsEnum;
export type CompanyEventType = keyof CompanyEventsType;

export enum InvoiceConfigEventsEnum {
  InvoiceConfigView = 'invoicing:config_screen_view',
  InvoiceConfigTypeChangeConfirmationView = 'invoicing:type_change_confirmation_view',
  InvoiceConfigTypeChangeErrorView = 'invoicing:type_change_error_view',
  InvoiceConfigTypeSelectionCLick = 'invoicing:type_selection_click',
  InvoiceConfigTypeChangeConfirmationCLick = 'invoicing:type_change_confirmation_click',
}

export type InvoiceConfigEventsType = typeof InvoiceConfigEventsEnum;
export type InvoiceConfigEventType = keyof InvoiceConfigEventsType;

export const AllCompanyEvents = {
  ...CompanyEventsEnum,
  ...InvoiceConfigEventsEnum,
};

export type AllCompanyEventsType = typeof AllCompanyEvents;
export type AllCompanyEventType = keyof AllCompanyEventsType;

export type CompanyEventTypeCollection = AllCompanyEventType[];

const CompanyEventKeys = Object.keys(AllCompanyEvents) as CompanyEventTypeCollection;

export const company: EventsDictionary = CompanyEventKeys.reduce((collection, eventKey) => {
  const eventName = AllCompanyEvents[eventKey];

  return {
    ...collection,
    [eventName]: <CompanyEventParams>(params: EventParamsType<CompanyEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
