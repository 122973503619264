import type { EventParamsType, EventsDictionary } from '../types';

export enum AdminEvents {
  AdminsHomeView = 'admin:accessMgmtHome_view',
  HomeClick = 'admin:accessMgmtHome_click',
  DataView = 'admin:accessMgmt_dataInfo_view',
  DataClick = 'admin:accessMgmt_dataInfo_click',
  DataCallback = 'admin:accessMgmt_dataInfo_callback',
  RoleView = 'admin:accessMgmt_roles_view',
  RoleClick = 'admin:accessMgmt_roles_click',
  LicenseView = 'admin:accessMgmt_license_view',
  LicenseClick = 'admin:accessMgmt_license_click',
  SuccessView = 'admin:accessMgmt_concludeInsert_view',
  AntiFraudAntiFraudView = 'AntiFraud:AntiFraudView',
  AntiFraudAntiFraudBackClick = 'AntiFraud:AntiFraudBackClick',
  AntiFraudAntiFraudBackHomeClick = 'AntiFraud:AntiFraudBackHomeClick',
  AntiFraudAntiFraudChatClick = 'AntiFraud:AntiFraudChatClick',
  InfoUpdateCallback = 'admin:accessMgmtInfoUpdate_callback',
}

export type AdminEventsType = typeof AdminEvents;

export type AdminEventType = keyof AdminEventsType;

export type AdminEventTypeCollection = AdminEventType[];

const ADMIN_EVENT_KEYS = Object.keys(AdminEvents) as AdminEventTypeCollection;

export const admin: EventsDictionary = ADMIN_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = AdminEvents[eventKey];

  return {
    ...collection,
    [eventName]: <EventParams>(params: EventParamsType<EventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
