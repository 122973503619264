import { isDevelopment } from '@benefits/common-utils';
import { Aboyeur } from '@ifood/aboyeur';

import { logger } from '../log/logger';
import { APP_VERSION, ENTITY } from './config';
import * as events from './events';
import { PLUGINS } from './plugins';
import { GlobalMetadataType } from './types';
import { parseFeatureName } from './utils/parseFeatureName';

export const globalEventMetadata = (companyMetadata?: Record<string, any>): GlobalMetadataType => {
  return {
    amplitude: {
      apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '',
      version: APP_VERSION,
      userId: companyMetadata?.userId,
    },
    faster: {
      version: APP_VERSION,
      secret: process.env.NEXT_PUBLIC_FASTER_SECRET_KEY ?? '',
      apiKey: process.env.NEXT_PUBLIC_FASTER_KEY ?? '',
      prefix: false,
    },
    googleAnalytics: {
      trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? '',
    },
  };
};

type InitEventDispatcherProps = { isTest?: boolean; dryRun?: boolean; companyMetadata?: Record<string, any> };

export const createEventDispatcher = (entity: string, features: any) => new Aboyeur(entity, features);

export const eventDispatcher = createEventDispatcher(`platform_b2b_${ENTITY}`, events);

type Acc = Record<string, Record<string, any>>;

export const serializeAllEvents = () => {
  const features = Object.keys(events.allEvents).reduce((acc: Acc, event: string) => {
    const featureName = parseFeatureName(event);

    const feature = featureName
      ? {
          [featureName]: {
            ...acc[featureName],
            [event]: events.allEvents[event],
          },
        }
      : {};

    return {
      ...acc,
      ...feature,
    };
  }, {});

  return features;
};

export const allEventsDispatcher = () => {
  const features = serializeAllEvents();
  const featuresKeys = Object.keys(features);

  const aboyeurInstances = featuresKeys.reduce((acc: any, feature: string) => {
    const entity = `platform_b2b_${feature}`;
    return {
      ...acc,
      [feature]: createEventDispatcher(entity, { [feature]: features[feature] }),
    };
  }, {});

  return aboyeurInstances;
};

export function initializeEventDispatcher({
  isTest = false,
  dryRun = isDevelopment(),
  companyMetadata = {},
}: InitEventDispatcherProps = {}): void {
  const config = {
    isTest,
    dryRun: isTest ? false : dryRun,
  };

  try {
    Aboyeur.initialize(globalEventMetadata(companyMetadata), PLUGINS, config);
  } catch {
    logger.error('Event dispatcher not initialized');
  }
}

export function updateAboyeurMetadata(newMetadata: Record<string, any>) {
  initializeEventDispatcher();
  Aboyeur.updateMetadata((metadata) => ({
    ...metadata,
    // add new services here to update metadata
    amplitude: { ...(metadata.amplitude || {}), ...(newMetadata?.amplitude || {}) },
  }));
}
