import { UserContext, useSessionStorage } from '@benefits/common-services';
import { useMutation, useQuery } from '@benefits/http-client';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { Userpilot } from 'userpilot';

import { getFeaturesFlags } from '../feature/feature';
import { ENABLED_FEATURES } from './enabled-features';

type EnabledFeaturesType = keyof typeof ENABLED_FEATURES;

export type FeatureContextType = {
  state?: {
    features?: EnabledFeaturesType;
    isLoading: boolean;
    isSuccess: boolean;
  };
  dispatch?: {
    clear: any;
    refetch: any;
  };
  isEnable: (feature: string) => boolean;
};

const FeatureContext = createContext<FeatureContextType | null>(null);
FeatureContext.displayName = 'FeatureContext';

const FeatureStore: React.FC<PropsWithChildren> = (props) => {
  const [features, saveFeatures] = useSessionStorage('_ife_feature_flags', undefined);
  const userContext = useContext(UserContext);

  const {
    mutate: mutateFeatureFlags,
    isLoading,
    isSuccess,
  } = useMutation(
    'features-flag',
    () => {
      return getFeaturesFlags(ENABLED_FEATURES);
    },
    {
      onSuccess: (data) => {
        const features = JSON.stringify(data.features);
        saveFeatures(features);
        if (userContext?.state?.user?.userId) {
          Userpilot.identify(userContext?.state?.user?.userId, {
            company: {
              id: userContext?.state?.user?.group?.id,
              billingAuthorityId: userContext?.state?.user?.group?.billingAuthorityId,
              ...data.features,
              ...userContext?.state?.user?.group?.metadata,
            },
            name: userContext?.state?.user?.userName,
            email: userContext?.state?.user?.email,
          });
        }
      },
    }
  );

  const refetch = () => {
    mutateFeatureFlags();
  };

  const isEnable = (feature: string) => {
    if (features) {
      const parsed = JSON.parse(features);
      return verifyFlag(parsed[feature]);
    }
    return false;
  };

  const verifyFlag = (data: string) => {
    return /true/g.test(data);
  };

  const clear = () => saveFeatures('');

  useEffect(() => {
    const hasGroup = userContext?.state?.user?.group;
    if (hasGroup && !features) {
      refetch();
    }
  }, [userContext?.state?.user, features]);

  return (
    <FeatureContext.Provider
      value={{
        state: {
          features,
          isLoading,
          isSuccess,
        },
        dispatch: {
          clear,
          refetch,
        },
        isEnable,
      }}
      {...props}
    />
  );
};

export { FeatureContext, FeatureStore };
